import React, { useState, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Navigate, useParams, Routes, Route, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  Button,
  Link,
  Stack,
  CardContent,
} from '@mui/material';

import { useSiteDetails, SiteDetail } from 'api/site';
import Advanced from 'component/svg/tabs/advanced.svg?react';
import Overview from 'component/svg/tabs/overview.svg?react';
import Backups from 'component/svg/tabs/backups.svg?react';
import Plugins from 'component/svg/tabs/plugins.svg?react';
import Reporting from 'component/svg/tabs/reporting.svg?react';
import Themes from 'component/svg/tabs/themes.svg?react';
import LogsIcon from 'component/svg/tabs/logs.svg?react';
import FileManagerIcon from 'component/svg/tabs/file-manager.svg?react';
import SecurityIcon from 'component/svg/tabs/security.svg?react';

import ClearCache from 'component/partial/ClearCache';
import { CurrentMode } from 'component/partial/CurrentMode';
import { Reporting as ReportingCard } from 'component/partial/Reporting';
import SitePlugins from './Plugins';
import { WpThemes } from './Themes';
import { Security } from './Security';
import BackupPage from './Backups';
import { Advanced as AdvancedTab } from './Advanced';
import { SiteDetailsOverview } from 'component/partial/SiteDetailsOverview';
import { FileManager } from 'component/partial/FileManager';
import { useTranslation } from 'react-i18next';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { isInIframe } from 'utils/iframe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faWordpress } from '@fortawesome/free-brands-svg-icons';
import { Edit } from '@mui/icons-material';
import { CircularProgress } from 'component/base/CircularProgress';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { NoSiteDetails } from './NoSiteDetails';
import { SiteFileContent } from 'component/partial/SiteFileContent';
import { ChangeLabelTooltip } from 'component/partial/ChangeLabelTooltip';
import { SiteSelectorMenu } from 'component/partial/SiteSelectorMenu';
import { cssVar } from 'utils/css';
import { isSiteAccess, useGetToken } from 'utils/token';
import { linkHelper } from 'linkHelper';
import Logs from './Logs/Logs';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import { SiteLockedPopover } from 'component/partial/SiteLockedPopover';
import { getSiteLockedStatus } from 'utils/site';
import { RoleGuard } from 'component/base/RoleGuard';
import { useCheckUserRole } from 'component/hooks/useCheckUserRole';
import { SiteStatus } from 'component/partial/SiteStatus';

const PREFIX = 'SiteDetails';

const classes = {
  visitUrl: `${PREFIX}VisitUrl`,
  loading: `${PREFIX}Loading`,
  changeLabelButton: `${PREFIX}ChangeLabelButton`,
  changeLabelIcon: `${PREFIX}ChangeLabelIcon`,
};

const Loader = styled('div')(({ theme }) => ({
  [`&`]: {
    left: '50%',
    padding: '0',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const Root = styled('main')(({ theme }) => ({
  [`& .${classes.visitUrl}`]: {
    position: 'relative',
    textDecoration: 'none',
  },

  [`& .${classes.changeLabelButton}`]: {
    top: '-4px',
    backgroundColor: cssVar('--color-ebb'),
    color: cssVar('--color-mine-shaft'),
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    padding: '0.25rem 0.5rem',
    '&:hover': {
      backgroundColor: cssVar('--color-wafer'),
      color: cssVar('--color-mine-shaft'),
    },
    '& svg': {
      color: cssVar('--primary'),
      height: '0.5em',
      width: 'auto',
      marginLeft: '0.313rem',
    },
  },

  dropdownButton: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    paddingBottom: 0,
    '& svg': {
      fontSize: '1.5rem',
      height: 'auto',
    },
  },

  [`& .MuiGrid-root:empty`]: {
    display: 'none',
  },
}));

type TabType =
  | 'file-manager'
  | 'overview'
  | 'plugins'
  | 'themes'
  | 'backups'
  | 'logs'
  | 'reporting'
  | 'security'
  | 'advanced';

function CardMenu({
  iconNode,
  tab,
  currentTab,
  onMenuChange,
  label,
}: {
  readonly iconNode: ReactNode;
  readonly tab: TabType;
  readonly currentTab: TabType | undefined;
  readonly onMenuChange: (tabType: TabType) => void;
  readonly label: string;
}) {
  const activeStyles =
    currentTab === tab
      ? {
          backgroundColor: cssVar('--color-white'),
          color: cssVar('--primary'),
        }
      : {};

  return (
    <Card
      sx={{
        backgroundColor: 'transparent',
        cursor: 'pointer',
        height: '6.5625rem',
        textAlign: 'center',
        width: '6.875rem',
        minWidth: '6.875rem',
        ...activeStyles,
        '&:hover': {
          backgroundColor: cssVar('--color-white'),
          color: cssVar('--primary'),
        },
        '&:not(:first-of-type)': {
          marginTop: '0',
        },
        '&:not(:last-child)': {
          marginBottom: '0',
        },
        '& i': {
          alignItems: 'center',
          display: 'flex',
          height: '2.5rem',
          margin: '0 auto 0.625rem auto',
          width: '2.5rem',
          '& svg': {
            display: 'block',
            maxHeight: '100%',
            maxWidth: '100%',
          },
        },
      }}
      onClick={() => onMenuChange(tab)}
    >
      <CardContent
        sx={{
          paddingLeft: '0',
          paddingRight: '0',
        }}
      >
        <i>{iconNode}</i>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
}

export function SiteDetails({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const { siteId = '' } = useParams<{ siteId: string; tabName?: TabType }>();
  const { sendMessage, getSdk } = React.useContext(PostMessageContext);
  const sdk = getSdk();
  const { isLoading, data, isFetched, isError, error } = useSiteDetails({ id: siteId });
  const [showSiteLabel, setShowSiteLabel] = useState(false);
  const siteDetails = data?.data.result;
  const { t } = useTranslation();
  const token = useGetToken();
  const staticSite: boolean = siteDetails?.site_type === 1 || false;
  const isSiteLocked = getSiteLockedStatus(siteDetails);
  const isBillingAdmin = useCheckUserRole('billing_admin');

  if (isLoading && !isFetched) {
    return (
      <Loader>
        <CircularProgress />
      </Loader>
    );
  }

  if (isError && isFetched && (error as any).response.status === 404) {
    return <NoSiteDetails />;
  }

  return (
    <Root key={siteId} id="mainContent">
      {siteDetails && showSiteLabel ? (
        <ChangeLabelTooltip site={siteDetails} onClose={() => setShowSiteLabel(false)} />
      ) : null}
      <Container maxWidth="lg">
        <Box>
          <Link
            className={classes.visitUrl}
            onClick={() => {
              if (isInIframe()) {
                sendMessage(
                  JSON.stringify({
                    type: 'externalLink',
                    data: {
                      link: `https://${siteDetails?.domain ?? ''}`,
                    },
                  })
                );
              } else {
                window.open(`https://${siteDetails?.domain ?? ''}`, '_blank', 'noreferrer');
              }
            }}
          >
            {t('visit_url')} <FontAwesomeIcon icon={faLongArrowRight} />
          </Link>
        </Box>
        <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
          <Grid item xs={12} md={8}>
            <Stack direction="row" alignItems="baseline" sx={{ flexWrap: 'wrap', gap: 1 }}>
              {sdk || isInIframe() ? (
                <Box>
                  <Typography variant="h1" data-testid="domainLabel">
                    {siteDetails?.domain ?? ''}
                  </Typography>
                </Box>
              ) : (
                <SiteSelectorMenu
                  siteDetails={data?.data.result}
                  isWpSiteControl={isWpSiteControl}
                />
              )}
              {!isSiteAccess(token) && !sdk ? (
                <Button
                  onClick={() => setShowSiteLabel(true)}
                  variant="contained"
                  size="small"
                  className={classes.changeLabelButton}
                  disabled={isBillingAdmin}
                >
                  {siteDetails?.label ?? t('add_label')}
                  {siteDetails?.label ? <Edit /> : null}
                </Button>
              ) : null}
            </Stack>
          </Grid>

          <Grid item xs={12} md="auto">
            <Grid container spacing={2}>
              <RoleGuard roles={['billing_admin']} type="block">
                <Grid item xs={12} sm="auto">
                  <ClearCache siteId={siteId} />
                </Grid>
                {!staticSite ? (
                  <Grid item xs={12} sm="auto">
                    <SiteLockedPopover isSiteLocked={isSiteLocked}>
                      <ProgressiveButton
                        onClick={() => {
                          if (isInIframe()) {
                            sendMessage(
                              JSON.stringify({
                                type: 'externalLink',
                                data: {
                                  relative: true,
                                  link: `/manage/${siteId}/wordpress?token=${window.sessionStorage.getItem(
                                    'user_session'
                                  )}`,
                                },
                              })
                            );
                          } else {
                            window.open(`/manage/${siteId}/wordpress`);
                          }
                        }}
                        variant="outlined"
                        color="primary"
                        startIcon={<FontAwesomeIcon icon={faWordpress} />}
                        endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}
                        fullWidth
                        disabled={isSiteLocked}
                        isLoading={isLoading}
                        text={t('wp_admin')}
                      />
                    </SiteLockedPopover>
                  </Grid>
                ) : null}
              </RoleGuard>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SiteStatus siteDetails={data?.data.result} />
          </Grid>
          <Grid item xs={12}>
            <CurrentMode siteDetails={data?.data.result} />
          </Grid>
          <Routes>
            <Route
              path={linkHelper.sites.siteDetails.tabs.path}
              element={
                <SiteDetailsTabContent
                  siteDetails={siteDetails}
                  isWpSiteControl={isWpSiteControl}
                />
              }
            />
            <Route index element={<Navigate to="overview" />} />
          </Routes>
        </Grid>
      </Container>
    </Root>
  );
}

const SiteDetailsTabContent = ({
  siteDetails,
  isWpSiteControl,
}: {
  readonly siteDetails: SiteDetail | undefined;
  readonly isWpSiteControl: boolean;
}) => {
  const { closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { siteId = '', tabName = 'overview' } = useParams<{ siteId: string; tabName?: TabType }>();
  const onMenuChange = (pageName: TabType) => {
    closeSnackbar();
    navigate(`/sites/${siteId}/${pageName}`);
  };
  const { t } = useTranslation();
  const staticSite: boolean = siteDetails?.site_type === 1 || false;

  const renderBody = () => {
    switch (tabName) {
      case 'plugins':
        if (!staticSite) {
          return <SitePlugins />;
        }
        return null;
      case 'overview':
        return <SiteDetailsOverview siteDetails={siteDetails} isWpSiteControl={isWpSiteControl} />;
      case 'themes':
        if (!staticSite) {
          return <WpThemes />;
        }
        return null;
      case 'advanced':
        return <AdvancedTab siteDetails={siteDetails} isWpSiteControl={isWpSiteControl} />;
      case 'backups':
        return <BackupPage siteDetails={siteDetails} />;
      case 'logs':
        return <Logs siteDetails={siteDetails} />;
      case 'security':
        return <Security />;
      case 'reporting':
        return <ReportingCard />;
      case 'file-manager':
        return (
          <Routes>
            <Route index element={<FileManager siteDetails={siteDetails} />} />
            <Route path="create" element={<SiteFileContent siteId={siteId ?? ''} />} />
            <Route path="view" element={<SiteFileContent siteId={siteId ?? ''} />} />
          </Routes>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            overflowX: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <CardMenu
            iconNode={<Overview />}
            onMenuChange={onMenuChange}
            label={t('overview')}
            currentTab={tabName}
            tab="overview"
          />
          {!staticSite ? (
            <CardMenu
              iconNode={<Plugins />}
              onMenuChange={onMenuChange}
              label={t('plugin_plural')}
              currentTab={tabName}
              tab="plugins"
            />
          ) : null}
          {!staticSite ? (
            <CardMenu
              iconNode={<Themes />}
              onMenuChange={onMenuChange}
              label={t('theme_plural')}
              currentTab={tabName}
              tab="themes"
            />
          ) : null}
          <RoleGuard roles={['billing_admin']} type="block">
            <CardMenu
              iconNode={<FileManagerIcon />}
              onMenuChange={onMenuChange}
              label={t('file_manager')}
              currentTab={tabName}
              tab="file-manager"
            />
          </RoleGuard>
          <CardMenu
            iconNode={<Backups />}
            onMenuChange={onMenuChange}
            label={t('backup_plural')}
            currentTab={tabName}
            tab="backups"
          />
          <CardMenu
            iconNode={<LogsIcon />}
            onMenuChange={onMenuChange}
            label={t('log_plural')}
            currentTab={tabName}
            tab="logs"
          />
          <CardMenu
            iconNode={<Reporting />}
            onMenuChange={onMenuChange}
            label={t('reporting')}
            currentTab={tabName}
            tab="reporting"
          />
          <CardMenu
            iconNode={<SecurityIcon />}
            onMenuChange={onMenuChange}
            label={t('security')}
            currentTab={tabName}
            tab="security"
          />
          <CardMenu
            iconNode={<Advanced />}
            onMenuChange={onMenuChange}
            label={t('advanced')}
            currentTab={tabName}
            tab="advanced"
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {renderBody()}
      </Grid>
    </>
  );
};
